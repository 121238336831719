.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}
form {
  /*border: 3px solid #f1f1f1;*/
}

.logoutIcon {
  float: right;
  margin: 10px;
  box-shadow: 0px 3px 19px 0px #17161687;
  padding: 4px 7px;
  border-radius: 6px;
  margin-bottom: 5px;
  color: #00a4bf;
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  padding-top: 60px;
}
/* Modal Content/Box */
.modal-content {
  padding: 10px;
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}
.bottomBtn{
  padding: 15px;
}
.bottomBtn .cancelbtna, .Okbtn{
  margin: 17px;
  font-size: 19px;
}

input[type="text"],
input[type="password"],
input[type="tel"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  background-color: #00a4bf;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

/*button:hover {
  opacity: 0.8;
}*/

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

.imgcontainer {
  text-align: center;
  margin: 100px 0 0 0;
}

img.avatar {
  width: 100px;
  /* border-radius: 50%; */
  height: 20vh;
}
img.lectureImg{
  width: 30%;
  object-fit: contain;
}
img.avatarLogin {
  width: 100px;
  /* border-radius: 50%; */
  /*height: 35vh;*/
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 1150px) {
  .main-qut-form {
    width: 100%;
    height: 100vh;
  }
  
}
@media screen and (max-width: 912px) {
  .qut-btm-buttom {
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: center;
    display: block !important;
  }
  img.lectureImg{
    width: 100%;
    object-fit: contain;
  }
}
@media screen and (max-width: 712px) {
  img.lectureImg{
    width: 100%;
    object-fit: contain;
  }
}
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .cancelbtn {
    width: 100%;
  }
}
.base-timer__label {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  /* background-color: #f44336; */
}
.loaderCenter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 
input[type=radio] {
  display:none; 
}
input[type=radio] + label {
  display:inline-block;
width:95%;
padding:10px;
border:1px solid #ddd;
margin-bottom:10px;
cursor:pointer;
}
input[type=radio] + label:hover{ border:1px solid #000000;}
input[type=radio]:checked + label { 
 background-image: none;
 background-color:#0C0;
color:#fff;
border:1px solid #0C0 !important;
-webkit-transition: all 0.2s ease-in-out;
-moz-transition: all 0.2s ease-in-out;
-o-transition: all 0.2s ease-in-out;
-ms-transition: all 0.2s ease-in-out;
transition: all 0.2s ease-in-out;
}

.worngans{ background-color:#F36;color:#fff; border:1px solid #F36 !important;-webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;} */

.option {
  padding: 2px;
  text-align: center;
  margin-bottom: 0px;
  width: 50%;
  display: -webkit-inline-box;
}
.option-que {
  padding: 0px 10px;
  /* text-align: center; */
  margin-bottom: 0px;
  /* width: 50%; */
  /* display: -webkit-inline-box; */
}
.option span {
  cursor: pointer;
  display: flow-root;
  text-align: center;
  margin: auto;
  padding: 3px 3px;
  background-color: #1f9cd5;
  width: 100%;
  color: white;
  font-size: 30px;
  font-weight: 900;
}

.option-que span {
  cursor: pointer;
  display: flow-root;
  /* text-align: center; */
  margin: auto;
  padding: 2px 3px;
  /* background-color: #1f9cd5; */
  width: 100%;
  /* color: white; */
  font-size: 18px;
  /* font-weight: 900; */
}
.scp-quizzes-data {
  padding: 0px 10px;
}

.active-option {
  box-shadow: 0px 1px 11px 2px #514a4a;
  background-color: "#00a4bf";
}

.main-qut-form .qut-btm-buttom {
  bottom: 0px;
  position: fixed;
  width: 100%;
  display: contents;
}

.qut_sub_btn {
  margin: 0px 0px;
  padding: 6px ,0px !important;
}

/* .animated {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation { 
  from { 
      opacity: 0; 
  } 
  to { 
      opacity: 1; 
  } 
}  */

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animated {
   -webkit-animation: flickerAnimation 3s infinite;
   -moz-animation: flickerAnimation 3s infinite;
   -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
}
